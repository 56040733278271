import CIcon from "@coreui/icons-react";
import { cilLockLocked } from "@coreui/icons-pro";
import { LoadingOverlay } from "../../../components";

const OtherLogout = () => {
  // Controllers

  // States

  // Actions

  // Utils
  const signOut = async () => {
    return;
  };

  return (
    <>
      <LoadingOverlay isOpen={false} />
      <div onClick={signOut}>
        <CIcon icon={cilLockLocked} className="me-2" />
        Sign Out
      </div>
    </>
  );
};

export default OtherLogout;
