import { TypedUseSelectorHook, useSelector } from "react-redux";
import {
  PtwLayoutStoreSlice,
  ptwAuthReducer,
  ptwLayoutReducer,
} from "../features";
import { PtwAuthStoreSlice } from "../features/authentication/datasources/_ptw.auth.store.slice";
import { ptwApiSlicev1 } from "./_.apis.v1";
// import {
//   PtwDailyApproval,
//   ptwHitoryApplicationn,
// } from "../features/history-user/application-history/datasources/_ptw.applicanthistory.api.slice";

export interface PtwStoreSlices {
  ptwLayout: PtwLayoutStoreSlice;
  ptwAuth: PtwAuthStoreSlice;
}

export const ptwReducers = {
  ptwLayout: ptwLayoutReducer,
  ptwAuth: ptwAuthReducer,
  [ptwApiSlicev1.reducerPath]: ptwApiSlicev1.reducer,
};

export const usePtwTypedSelector: TypedUseSelectorHook<PtwStoreSlices> =
  useSelector;

export const ptwApiMiddlewares = [ptwApiSlicev1.middleware];
