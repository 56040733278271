import React, { FC } from "react";
import {
  CNavItem,
  CSidebar,
  CSidebarBrand,
  CSidebarNav,
  CSidebarToggler,
} from "@coreui/react-pro";

import { ApproverSidebarNav } from "./widgets/ApproverSidebarNav";

import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";

import { logoNegative as approverLogo } from "../../../../assets/brand/coreui-logo-sidebar";
import { sygnet as approverCygnet } from "../../../../assets/brand/coreui-sygnet-sidebar";

// sidebar nav config
import navigation from "../../../configs/_approver.sidenav";
import { useApproverSidebarController } from "../controllers/useApproverSidebar";
import CIcon from "@coreui/icons-react";
import { cilShield } from "@coreui/icons-pro";
import { MainAppsRepo } from "../../../../Apps";

interface ApproverSidebarProps {
  changeApp?: (app: MainAppsRepo) => void;
}

const ApproverSidebar: FC<ApproverSidebarProps> = (props) => {
  const {
    state: { sidebarShow, unfoldable },
    action: { setSidebarShow, toggleSidebarFold },
  } = useApproverSidebarController();

  const goToApp = () => {
    if (props.changeApp) {
      props.changeApp(MainAppsRepo.Approver);
    }
    return;
  };

  return (
    <CSidebar
      position="fixed"
      unfoldable={unfoldable}
      visible={sidebarShow}
      onVisibleChange={(visible) => {
        setSidebarShow(visible);
      }}
    >
      <CSidebarBrand className="d-none d-md-flex">
        <CIcon className="sidebar-brand-full" icon={approverLogo} height={35} />
        <CIcon
          className="sidebar-brand-narrow"
          icon={approverCygnet}
          height={35}
        />
      </CSidebarBrand>
      <CSidebarNav>
        <SimpleBar>
          <ApproverSidebarNav items={navigation} />
        </SimpleBar>
        <CNavItem
          component={"div"}
          to="#"
          onClick={goToApp}
          className="mt-auto"
        >
          <CIcon icon={cilShield} customClassName="nav-icon" />
          Apps
        </CNavItem>
      </CSidebarNav>
      <CSidebarToggler
        className="d-none d-lg-flex"
        onClick={() => {
          toggleSidebarFold();
        }}
      />
    </CSidebar>
  );
};

export default React.memo(ApproverSidebar);
