import { useDispatch } from "react-redux";
import { useTypedSelector } from "../../../../store";
import {
  storeStarterSelectAsideShow,
  storeOtherSelectSidebarShow,
  storeOtherSetLayout,
} from "../datasources/_other.layout.store.slice";

export const useOtherHeaderController = () => {
  const dispatch = useDispatch();
  const sidebarShow = useTypedSelector(storeOtherSelectSidebarShow);
  const asideShow = useTypedSelector(storeStarterSelectAsideShow);

  const setSidebarShow = (sidebarShow: boolean) => {
    dispatch(storeOtherSetLayout({ sidebarShow: sidebarShow }));
  };

  const setAsideShow = (asideShow: boolean) => {
    dispatch(storeOtherSetLayout({ asideShow: asideShow }));
  };

  return {
    state: {
      sidebarShow,
      asideShow,
    },
    action: {
      setSidebarShow,
      setAsideShow,
    },
  };
};
