import { useDispatch } from "react-redux";
import { useTypedSelector } from "../../../../store";
import {
  storeOtherSelectSidebarShow,
  storeStarterSelectSidebarUnfoldable,
  storeOtherSetLayout,
} from "../datasources/_other.layout.store.slice";

export const useOtherSidebarController = () => {
  const dispatch = useDispatch();
  const unfoldable = useTypedSelector(storeStarterSelectSidebarUnfoldable);
  const sidebarShow = useTypedSelector(storeOtherSelectSidebarShow);

  const setSidebarShow = (visible: boolean) => {
    dispatch(storeOtherSetLayout({ type: "set", sidebarShow: visible }));
  };
  const toggleSidebarFold = () => {
    dispatch(
      storeOtherSetLayout({ type: "set", sidebarUnfoldable: !unfoldable }),
    );
  };

  return {
    state: {
      unfoldable,
      sidebarShow,
    },
    action: {
      setSidebarShow,
      toggleSidebarFold,
    },
  };
};
