import { createSlice } from "@reduxjs/toolkit";
import { ReduxStore } from "../../../../store";

export interface ApproverLayoutStoreSlice {
  sidebarShow: boolean;
  sidebarUnfoldable: boolean;
  asideShow: boolean;
  theme: string;
}

const initialState: ApproverLayoutStoreSlice = {
  sidebarShow: true,
  sidebarUnfoldable: false,
  asideShow: false,
  theme: "light",
};

const approverLayoutSlice = createSlice({
  name: "approverLayout",
  initialState,
  reducers: {
    storeApproverSetLayout: (state, { payload }) => {
      return { ...state, ...payload };
    },
  },
});

// Actions
export const { storeApproverSetLayout: storeApproverSetLayout } =
  approverLayoutSlice.actions;

// Selectors
export const storeApproverSelectLayout = (state: ReduxStore) =>
  state.approverLayout;

export const storeApproverSelectSidebarShow = (state: ReduxStore) =>
  state.approverLayout.sidebarShow;

export const storeApproverSelectSidebarUnfoldable = (state: ReduxStore) =>
  state.approverLayout.sidebarUnfoldable;

export const storeApproverSelectAsideShow = (state: ReduxStore) =>
  state.approverLayout.asideShow;

export const storeApproverSelectTheme = (state: ReduxStore) =>
  state.approverLayout.theme;

// Reducer
export default approverLayoutSlice.reducer;
