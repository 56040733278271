import { useDispatch } from "react-redux";
import {
  storePtwSelectTheme,
  storePtwSetLayout,
} from "../datasources/_.layout.store.slice";
import { usePtwTypedSelector } from "../../../configs/_.store.slice";

export const usePtwThemeSwitcher = () => {
  const dispatch = useDispatch();
  const theme = usePtwTypedSelector(storePtwSelectTheme);

  theme === "dark"
    ? document.body.classList.add("dark-theme")
    : document.body.classList.remove("dark-theme");

  const setLightTheme = () => {
    dispatch(storePtwSetLayout({ theme: "light" }));
  };

  const setDarkTheme = () => {
    dispatch(storePtwSetLayout({ theme: "dark" }));
  };

  return {
    state: { theme },
    action: { setLightTheme, setDarkTheme },
  };
};
