import { useApproverRestoreAuthRepo } from "../../authentication/repositories/useApproverRestoreAuthRepo";

export const useApproverLayoutController = () => {
  const {
    state: { loading, isAuthenticated },
  } = useApproverRestoreAuthRepo();

  return {
    state: {
      loading,
      isAuthenticated,
    },
  };
};
