import CIcon from "@coreui/icons-react";
import { cilMoon, cilSun } from "@coreui/icons-pro";
import { CButtonGroup, CFormCheck } from "@coreui/react-pro";
import { useApproverThemeSwitcher } from "../../controllers/useApproverThemeSwitcher";

const ApproverThemeSwitcher = () => {
  const {
    state: { theme },
    action: { setLightTheme, setDarkTheme },
  } = useApproverThemeSwitcher();

  return (
    <CButtonGroup aria-label="Theme switch">
      <CFormCheck
        type="radio"
        button={{ color: "primary" }}
        name="theme-switch"
        id="btn-light-theme"
        autoComplete="off"
        label={<CIcon icon={cilSun} />}
        checked={theme === "light"}
        onChange={setLightTheme}
      />
      <CFormCheck
        type="radio"
        button={{ color: "primary" }}
        name="theme-switch"
        id="btn-dark-theme"
        autoComplete="off"
        label={<CIcon icon={cilMoon} />}
        checked={theme === "dark"}
        onChange={setDarkTheme}
      />
    </CButtonGroup>
  );
};

export default ApproverThemeSwitcher;
