import React from "react";
import { useLocation } from "react-router-dom";

import { CBreadcrumb } from "@coreui/react-pro";
import { SBreadcrumbItem } from "../../../../../components";
import approverRoutes from "../../../../configs/_approver.routes";

type breadcrumb = {
  pathname?: string;
  name?: boolean | string;
  active?: boolean;
};

type route = {
  path: string;
  name: string;
  disabled?: undefined | boolean;
  paramIndex?: number;
};

const ApproverBreadcrumb = () => {
  const currentLocation = useLocation().pathname;

  const getRouteName = (pathname: string, routes: route[]) => {
    const currentRoute = routes.find((route) => route.path === pathname);
    return {
      routeName: currentRoute ? currentRoute.name : false,
      disabled: currentRoute?.disabled !== undefined ?? currentRoute?.disabled,
    };
  };

  const getBreadcrumbs = (location: string) => {
    const breadcrumbs: breadcrumb[] = [];
    location.split("/").reduce((prev, curr, index, array) => {
      const currentPathname = `${prev}/${curr}`;
      const { routeName, disabled } = getRouteName(
        currentPathname,
        approverRoutes,
      );
      let active = index + 1 === array.length ? true : false;
      if (disabled) active = true;
      if (!routeName) return currentPathname;
      breadcrumbs.push({
        pathname: "approver/" + currentPathname,
        name: routeName,
        active,
      });
      return currentPathname;
    });
    return breadcrumbs;
  };

  const breadcrumbs = getBreadcrumbs(currentLocation);

  return (
    <CBreadcrumb className="m-0 ms-2">
      <SBreadcrumbItem href="/" navlink>
        Home
      </SBreadcrumbItem>
      {breadcrumbs.map((breadcrumb, index) => {
        return (
          <SBreadcrumbItem
            {...(breadcrumb.active
              ? { active: true }
              : { href: breadcrumb.pathname, navlink: true })}
            key={index}
          >
            {breadcrumb.name}
          </SBreadcrumbItem>
        );
      })}
    </CBreadcrumb>
  );
};

export default React.memo(ApproverBreadcrumb);
