import { usePtwRestoreAuthRepo } from "../../authentication/repositories/usePtwRestoreAuthRepo";

export const usePtwLayoutController = () => {
  const [{ loading, isAuthenticated }] = usePtwRestoreAuthRepo();

  return {
    state: {
      loading,
      isAuthenticated,
    },
  };
};
