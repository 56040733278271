import React, { FC, Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { PtwRoutePath } from "./configs/_.route-path";
import ptwRoutes from "./configs/_.routes";
import { MainAppsRepo } from "../Apps";
import { LoadingOverlay } from "../components";
import { usePtwThemeSwitcher } from "./features/layout/controllers";
import { usePtwTypedSelector } from "./configs/_.store.slice";
import { storePtwSelectLoginType } from "./features/authentication/datasources/_ptw.auth.store.slice";
import { LoginType } from "./features/authentication/datasources/constants/login-type";

const PtwAppLayout = React.lazy(() => import("./features/layout/PtwAppLayout"));
const PtwLogin = React.lazy(
  () =>
    import("./features/authentication/components/contents/ptw-login/PtwLogin"),
);

interface AppPtwProps {
  changeApp(app: MainAppsRepo): void;
}

const AppPtw: FC<AppPtwProps> = ({ changeApp }) => {
  // Redux Hooks
  const loginType = usePtwTypedSelector(storePtwSelectLoginType);

  const {
    state: { theme },
  } = usePtwThemeSwitcher();
  return (
    <div className={theme !== "dark" ? "pss-light" : "pss-dark"}>
      <Suspense fallback={<LoadingOverlay isOpen={true} />}>
        <Routes>
          <Route path={PtwRoutePath.Login} element={<PtwLogin />} />
          <Route
            path={PtwRoutePath.Home}
            element={<PtwAppLayout changeApp={changeApp} />}
          >
            {ptwRoutes.map((route, idx) => {
              return (
                route.component && (
                  <Route
                    key={idx}
                    path={route.path}
                    element={React.createElement(route.component)}
                  />
                )
              );
            })}
            {loginType === LoginType.Applicant && (
              <Route
                path={PtwRoutePath.Home}
                element={<Navigate to={PtwRoutePath.Dashboard} />}
              />
            )}
            {loginType === LoginType.Approver && (
              <Route
                path={PtwRoutePath.Home}
                element={<Navigate to={PtwRoutePath.DashboardApprover} />}
              />
            )}
            {loginType === LoginType.AuxPolice && (
              <Route
                path={PtwRoutePath.Home}
                element={<Navigate to={PtwRoutePath.DashboardAuxPolice} />}
              />
            )}
            <Route
              path="*"
              element={
                <Navigate to={PtwRoutePath.NotFoundError} replace={true} />
              }
            />
          </Route>
          <Route path="*" element={<Navigate to={PtwRoutePath.Home} />} />
        </Routes>
      </Suspense>
    </div>
  );
};
export default AppPtw;
