import { createSlice } from "@reduxjs/toolkit";
import { ReduxStore } from "../../../../store";

export interface OtherLayoutStoreSlice {
  sidebarShow: boolean;
  sidebarUnfoldable: boolean;
  asideShow: boolean;
  theme: string;
}

const initialState: OtherLayoutStoreSlice = {
  sidebarShow: true,
  sidebarUnfoldable: false,
  asideShow: false,
  theme: "light",
};

const otherLayoutSlice = createSlice({
  name: "otherLayout",
  initialState,
  reducers: {
    storeOtherSetLayout: (state, { payload }) => {
      return { ...state, ...payload };
    },
  },
});

// Actions
export const { storeOtherSetLayout: storeOtherSetLayout } =
  otherLayoutSlice.actions;

// Selectors
export const storeOtherSelectLayout = (state: ReduxStore) => state.otherLayout;

export const storeOtherSelectSidebarShow = (state: ReduxStore) =>
  state.otherLayout.sidebarShow;

export const storeStarterSelectSidebarUnfoldable = (state: ReduxStore) =>
  state.otherLayout.sidebarUnfoldable;

export const storeStarterSelectAsideShow = (state: ReduxStore) =>
  state.otherLayout.asideShow;

export const storeStarterSelectTheme = (state: ReduxStore) =>
  state.otherLayout.theme;

// Reducer
export default otherLayoutSlice.reducer;
