import {
  BaseQueryApi,
  FetchArgs,
  createApi,
  fetchBaseQuery,
} from "@reduxjs/toolkit/query/react";

import {
  storePtwClearCredential,
  storePtwSetCredential,
} from "../features/authentication/datasources/_ptw.auth.store.slice";

import qs from "qs";
import {
  webStorePtwClearAuth,
  webStorePtwSaveAuth,
} from "../features/authentication/datasources/web.storage";
import { ReduxStore } from "../../store";
import { HttpErrorResponse, LogonResponse } from "../types/responses";
import { ApiError } from "../types";

enum DeploymentMode {
  LIVE = "live",
  LOCAL = "local",
}

interface DevEnv {
  live: string;
  local: string;
  mode: string;
  enable: boolean;
}

const devenv: DevEnv = {
  live:
    process.env.REACT_APP_USE_DEV_BACKEND_LIVE_URL ??
    "https://ptw.dev.innates.my/api",
  local:
    process.env.REACT_APP_USE_DEV_BACKEND_LOCAL_URL ??
    "http://localhost:4000/api",
  mode: process.env.REACT_APP_USE_DEV_BACKEND ?? DeploymentMode.LIVE,
  enable:
    process.env.NODE_ENV !== "production" ||
    process.env.REACT_APP_BUILD_MODE === "development",
};

let baseUrl = "";
if (devenv.enable) {
  baseUrl = devenv.mode === DeploymentMode.LIVE ? devenv.live : devenv.local;
} else {
  const url = new URL(window.location.origin);
  baseUrl = url.origin + "/api";
}

if (process.env.NODE_ENV !== "production") {
  console.log("(Development Mode) baseUrl:", baseUrl);
}

const baseQuery = fetchBaseQuery({
  baseUrl,
  paramsSerializer: (params) => {
    return qs.stringify(params);
  },
  prepareHeaders: (headers, api) => {
    const { getState } = api;
    const token = (getState() as ReduxStore).ptwAuth.accessToken;
    if (token) {
      headers.set("authorization", token);
    }
    return headers;
  },
});

const refreshTokenQuery = fetchBaseQuery({
  baseUrl,
  prepareHeaders: (headers, api) => {
    const { getState } = api;
    const token = (getState() as ReduxStore).ptwAuth.refreshToken;
    if (token) {
      headers.set("authorization", token);
    }
    return headers;
  },
});

const baseQueryWithReauth = async (
  args: string | FetchArgs,
  api: BaseQueryApi,
  extraOptions: object,
) => {
  let result = await baseQuery(args, api, { ...extraOptions, refresh: true });
  if (result?.error) {
    console.error("HttpResponse:", result);
    if (typeof result.error.status === "number") {
      const response = result.error.data as ApiError;
      result.error.data = { ...response.error, originalData: null };
    } else {
      let message = "";
      let statusCode = 500;
      let data: unknown | undefined;
      if (result.error.status === "FETCH_ERROR") {
        message = result.error.error;
        statusCode = 500;
        data = result.error.data;
      }
      if (result.error.status === "PARSING_ERROR") {
        message =
          result.error.status +
          ": " +
          result.error.error +
          " for " +
          result.meta?.request.url;
        statusCode = result.error.originalStatus;
        data = result.error.data;
      }
      if (result.error.status === "TIMEOUT_ERROR") {
        message = result.error.status + ": " + result.error.error;
        statusCode = 408;
        data = result.error.data;
      }
      if (result.error.status === "CUSTOM_ERROR") {
        message = result.error.status + ": " + result.error.error;
        statusCode = 400;
        data = result.error.data;
      }
      result.error.data = {
        message: message,
        name: result.error.status,
        statusCode: statusCode,
        originalData: data,
      };
    }

    console.error("HttpError:", result.error.data);
  }

  if (result?.error?.status === 401) {
    if (
      (result?.error?.data as HttpErrorResponse).name ===
      "TokenExpiredError/AccessToken"
    ) {
      // send refresh token to get new access token
      console.log("Reauthenticating ...");
      const refreshResult = await refreshTokenQuery(
        "/users/refresh-login",
        api,
        extraOptions,
      );
      if (refreshResult?.error) {
        await baseQuery("/users/logout", api, extraOptions);
        webStorePtwClearAuth();
        api.dispatch(storePtwClearCredential());
      } else {
        if (refreshResult?.data) {
          console.log("Reauthentication was successful.");
          const logon = refreshResult.data as LogonResponse;
          // store the new token
          webStorePtwSaveAuth(logon, true);
          api.dispatch(storePtwSetCredential(logon));

          // retry the original query with new access token
          result = await baseQuery(args, api, extraOptions);
        }
      }
    }
    // else {
    //   console.log('Reauthentication failed.')
    //   await baseQuery('/users/logout', api, extraOptions)
    //   clearPtwAuthFromStorage()
    //   api.dispatch(storePtwClearCredential())
    // }
  }
  return result;
};

export const PtwApiTags = {
  DashboardStatus: "DashboardStatuses",
  UsersWithRelation: "UsersWithRelation",
  UserByIdWithRelation: "UserByIdWithRelation",
  PermitWithRelation: "PermitWithRelation",
  AllTags: "AllTags",
};

export const ptwApiSlicev1 = createApi({
  reducerPath: "ptwApisVer1",
  baseQuery: baseQueryWithReauth,
  tagTypes: [
    PtwApiTags.DashboardStatus,
    PtwApiTags.UsersWithRelation,
    PtwApiTags.PermitWithRelation,
    PtwApiTags.AllTags,
  ],
  endpoints: (_builder) => ({}),
});
