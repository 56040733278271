import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

export const useApproverRestoreAuthRepo = () => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const logonString =
      localStorage.getItem("ApproverLogon") ??
      sessionStorage.getItem("ApproverLogon");

    if (!logonString) {
      setLoading(false);
      return;
    }
    try {
      // const logon = JSON.parse(logonString);
      // set logon to redux store
      setLoading(false);
    } catch (e) {
      return setLoading(false);
    }
  }, [dispatch]);
  return {
    state: {
      loading,
      isAuthenticated: true,
    },
  };
};
