import { useDispatch } from "react-redux";
import { useTypedSelector } from "../../../../store";
import {
  storeApproverSelectAsideShow,
  storeApproverSelectSidebarShow,
  storeApproverSetLayout,
} from "../datasources/_approver.layout.store.slice";

export const useApproverHeaderController = () => {
  const dispatch = useDispatch();
  const sidebarShow = useTypedSelector(storeApproverSelectSidebarShow);
  const asideShow = useTypedSelector(storeApproverSelectAsideShow);

  const setSidebarShow = (sidebarShow: boolean) => {
    dispatch(storeApproverSetLayout({ sidebarShow: sidebarShow }));
  };

  const setAsideShow = (asideShow: boolean) => {
    dispatch(storeApproverSetLayout({ asideShow: asideShow }));
  };

  return {
    state: {
      sidebarShow,
      asideShow,
    },
    action: {
      setSidebarShow,
      setAsideShow,
    },
  };
};
