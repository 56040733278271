import React from "react";
import { CFooter } from "@coreui/react-pro";
import moment from "moment";

const OtherFooter = () => {
  return (
    <CFooter>
      <div>
        <a
          href="https://www.innates.my"
          target="_blank"
          rel="noopener noreferrer"
        >
          Significs Sdn. Bhd.
        </a>
        <span className="ms-1">&copy; {moment().format("YYYY")} .innates</span>
      </div>
      <div className="ms-auto">
        <p className="me-1">
          Powered by <span className="text-primary">IoT Wave</span>
        </p>
      </div>
    </CFooter>
  );
};

export default React.memo(OtherFooter);
