import { configureStore } from "@reduxjs/toolkit";
import { useSelector, TypedUseSelectorHook } from "react-redux";
import {
  ApproverStoreSlices,
  approverReducers,
} from "./app.approver/configs/_approver.store.slice";
import {
  OtherStoreSlices,
  otherReducers,
} from "./app.other/configs/_other.store.slice";
import {
  PtwStoreSlices,
  ptwReducers,
  ptwApiMiddlewares,
} from "./app.ptw/configs/_.store.slice";

//eslint:disable-next-line:no-empty-interface
export interface ReduxStore
  extends PtwStoreSlices,
    ApproverStoreSlices,
    OtherStoreSlices {
  additionalProp?: unknown;
}

const store = configureStore({
  reducer: {
    ...ptwReducers,
    ...approverReducers,
    ...otherReducers,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(...ptwApiMiddlewares),
  devTools: true,
});

export default store;

// https://react-redux.js.org/using-react-redux/static-typing#typing-the-useselector-hook
export const useTypedSelector: TypedUseSelectorHook<ReduxStore> = useSelector;
