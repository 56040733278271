import { useDispatch } from "react-redux";
import { useTypedSelector } from "../../../../store";
import {
  storeStarterSelectTheme,
  storeOtherSetLayout,
} from "../datasources/_other.layout.store.slice";

export const useOtherThemeSwitcher = () => {
  const dispatch = useDispatch();
  const theme = useTypedSelector(storeStarterSelectTheme);

  theme === "dark"
    ? document.body.classList.add("dark-theme")
    : document.body.classList.remove("dark-theme");

  const setLightTheme = () => {
    dispatch(storeOtherSetLayout({ theme: "light" }));
  };

  const setDarkTheme = () => {
    dispatch(storeOtherSetLayout({ theme: "dark" }));
  };

  return {
    state: { theme },
    action: { setLightTheme, setDarkTheme },
  };
};
