import { NavLink } from "react-router-dom";
import {
  CContainer,
  CHeader,
  CHeaderBrand,
  CHeaderDivider,
  CHeaderNav,
  CHeaderToggler,
  CNavLink,
  CNavItem,
} from "@coreui/react-pro";
import { cilMenu } from "@coreui/icons-pro";
import CIcon from "@coreui/icons-react";
import ApproverBreadcrumb from "./widgets/ApproverBreadcrumb";
import ApproverAvatarDropdown from "./widgets/ApproverAvatarDropdown";
import ApproverThemeSwitcher from "./widgets/ApproverThemeSwitcher";
import { ApproverRoutePath } from "../../../configs/_approver.route-path";
import { useApproverHeaderController } from "../controllers/useApproverHeaderController";
import { logo as approverLogo } from "../../../../assets/brand/coreui-logo-header";

const ApproverHeader = (): JSX.Element => {
  const {
    state: { sidebarShow },
    action: { setSidebarShow },
  } = useApproverHeaderController();

  return (
    <CHeader position="sticky" className="mb-4">
      <CContainer fluid>
        <CHeaderToggler
          className="ps-1"
          onClick={() => setSidebarShow(!sidebarShow)}
        >
          <CIcon icon={cilMenu} size="lg" />
        </CHeaderToggler>
        <CHeaderBrand className="mx-auto d-md-none">
          <CIcon icon={approverLogo} height={48} aria-valuetext="Logo" />
        </CHeaderBrand>
        <CHeaderNav className="d-none d-md-flex me-auto">
          <CNavItem>
            <CNavLink to={ApproverRoutePath.Dashboard} component={NavLink}>
              Dashboard
            </CNavLink>
          </CNavItem>
          <CNavItem>
            <CNavLink href="#">Users</CNavLink>
          </CNavItem>
          <CNavItem>
            <CNavLink to="/coreui" component={NavLink}>
              CoreUi
            </CNavLink>
          </CNavItem>
          <CNavItem>
            <CNavLink to="/other" component={NavLink}>
              Other
            </CNavLink>
          </CNavItem>
        </CHeaderNav>
        <CHeaderNav className="ms-auto me-4">
          <ApproverThemeSwitcher />
        </CHeaderNav>
        <CHeaderNav className="ms-2 me-2">
          <ApproverAvatarDropdown />
        </CHeaderNav>
      </CContainer>
      <CHeaderDivider />
      <CContainer fluid>
        <ApproverBreadcrumb />
      </CContainer>
    </CHeader>
  );
};

export default ApproverHeader;
