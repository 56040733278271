import { useDispatch } from "react-redux";
import {
  storePtwSelectAsideShow,
  storePtwSelectSidebarShow,
  storePtwSetLayout,
} from "../datasources/_.layout.store.slice";
import { usePtwTypedSelector } from "../../../configs/_.store.slice";

export const usePtwHeaderController = () => {
  const dispatch = useDispatch();
  const sidebarShow = usePtwTypedSelector(storePtwSelectSidebarShow);
  const asideShow = usePtwTypedSelector(storePtwSelectAsideShow);

  const setSidebarShow = (sidebarShow: boolean) => {
    dispatch(storePtwSetLayout({ sidebarShow: sidebarShow }));
  };

  const setAsideShow = (asideShow: boolean) => {
    dispatch(storePtwSetLayout({ asideShow: asideShow }));
  };

  return {
    state: {
      sidebarShow,
      asideShow,
    },
    action: {
      setSidebarShow,
      setAsideShow,
    },
  };
};
