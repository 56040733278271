import React, { ReactNode } from "react";
import { OtherRoutePath } from "./_other.route-path";
const OtherDashboard = React.lazy(
  () => import("../features/dashboard/OtherDashboard"),
);
const OtherNotFoundError = React.lazy(
  () => import("../features/errors/OtherNotFoundError"),
);
const OtherInternalServerError = React.lazy(
  () => import("../features/errors/OtherInternalServerError"),
);

export type route = {
  component?: ReactNode;
  name?: string;
  path?: string;
  routes?: route[];
  paramIndex?: number;
  disabled?: undefined | boolean;
};

const otherRoutes = [
  { path: "/other", name: "Other" },
  {
    path: OtherRoutePath.Dashboard,
    name: "Dashboard",
    component: OtherDashboard,
  },
  {
    path: OtherRoutePath.NotFoundError,
    name: "Page Not Found",
    component: OtherNotFoundError,
  },
  {
    path: OtherRoutePath.InternalServerError,
    name: "Internal Server Error",
    component: OtherInternalServerError,
  },
];

export default otherRoutes;
