import { LogonResponse } from "../../../../types/responses";

export const webStorePtwSaveAuth = (
  logon: LogonResponse,
  // role: LogonResponse["role"],
  rememberMe?: boolean,
) => {
  if (rememberMe) {
    localStorage.setItem("PtwLogon", JSON.stringify(logon));
  } else {
    sessionStorage.setItem("PtwLogon", JSON.stringify(logon));
  }
};
