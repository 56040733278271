import { NavLink } from "react-router-dom";
import {
  CContainer,
  CHeader,
  CHeaderBrand,
  CHeaderDivider,
  CHeaderNav,
  CHeaderToggler,
  CNavLink,
  CNavItem,
} from "@coreui/react-pro";
import { cilMenu } from "@coreui/icons-pro";
import CIcon from "@coreui/icons-react";
import OtherBreadcrumb from "./widgets/OtherBreadcrumb";
import OtherAvatarDropdown from "./widgets/OtherAvatarDropdown";
import OtherThemeSwitcher from "./widgets/OtherThemeSwitcher";
import { OtherRoutePath } from "../../../configs/_other.route-path";
import { useOtherHeaderController } from "../controllers/useOtherHeaderController";
import { logo as starterLogo } from "../../../../assets/brand/coreui-logo-header";

const OtherHeader = (): JSX.Element => {
  const {
    state: { sidebarShow },
    action: { setSidebarShow },
  } = useOtherHeaderController();

  return (
    <CHeader position="sticky" className="mb-4">
      <CContainer fluid>
        <CHeaderToggler
          className="ps-1"
          onClick={() => setSidebarShow(!sidebarShow)}
        >
          <CIcon icon={cilMenu} size="lg" />
        </CHeaderToggler>
        <CHeaderBrand className="mx-auto d-md-none">
          <CIcon icon={starterLogo} height={48} aria-valuetext="Logo" />
        </CHeaderBrand>
        <CHeaderNav className="d-none d-md-flex me-auto">
          <CNavItem>
            <CNavLink to={OtherRoutePath.Dashboard} component={NavLink}>
              Dashboard
            </CNavLink>
          </CNavItem>
          <CNavItem>
            <CNavLink href="#">Users</CNavLink>
          </CNavItem>
          <CNavItem>
            <CNavLink to={"/"} component={NavLink}>
              Main
            </CNavLink>
          </CNavItem>
        </CHeaderNav>
        <CHeaderNav className="ms-auto me-4">
          <OtherThemeSwitcher />
        </CHeaderNav>
        <CHeaderNav className="ms-2 me-2">
          <OtherAvatarDropdown />
        </CHeaderNav>
      </CContainer>
      <CHeaderDivider />
      <CContainer fluid>
        <OtherBreadcrumb />
      </CContainer>
    </CHeader>
  );
};

export default OtherHeader;
