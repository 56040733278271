import React from "react";
import {
  CAvatar,
  CDropdown,
  CDropdownDivider,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
} from "@coreui/react-pro";
import { cilUser } from "@coreui/icons";
import CIcon from "@coreui/icons-react";

import avatar8 from "../../../../../assets/images/avatars/8.jpg";
import OtherLogout from "../../../authentication/OtherLogout";

const OtherAvatarDropdown = () => {
  return (
    <CDropdown variant="nav-item" alignment="end">
      <CDropdownToggle className="py-0" caret={false}>
        <CAvatar src={avatar8} size="md" />
      </CDropdownToggle>
      <CDropdownMenu className="pt-0">
        <CDropdownItem href="#">
          <CIcon icon={cilUser} className="me-2" />
          Profile
        </CDropdownItem>
        <CDropdownDivider />
        <CDropdownItem>
          <OtherLogout />
        </CDropdownItem>
      </CDropdownMenu>
    </CDropdown>
  );
};

export default OtherAvatarDropdown;
