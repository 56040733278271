import React, { FC, Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { ApproverRoutePath } from "./configs/_approver.route-path";
import approverRoutes from "./configs/_approver.routes";
import ApproverAppLayout from "./features/layout/ApproverAppLayout";
const ApproverDashboard = React.lazy(
  () => import("./features/dashboard/ApproverDashboard"),
);
import { MainAppsRepo } from "../Apps";

interface AppApproverProps {
  changeApp?: (app: MainAppsRepo) => void;
}

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

const AppApprover: FC<AppApproverProps> = ({ changeApp }) => {
  return (
    <div className="starter">
      <Suspense fallback={loading}>
        <Routes>
          <Route
            path={ApproverRoutePath.Home}
            element={<ApproverAppLayout changeApp={changeApp} />}
          >
            {approverRoutes.map((route, idx) => {
              return (
                route.component && (
                  <Route
                    key={idx}
                    path={route.path}
                    element={<route.component />}
                  />
                )
              );
            })}
            <Route
              path={ApproverRoutePath.Home}
              element={<Navigate to={ApproverRoutePath.Dashboard} />}
            />
            <Route
              path={ApproverRoutePath.Dashboard}
              element={<ApproverDashboard />}
            />
            <Route
              path="*"
              element={
                <Navigate to={ApproverRoutePath.NotFoundError} replace={true} />
              }
            />
          </Route>
          <Route path="*" element={<Navigate to={ApproverRoutePath.Home} />} />
        </Routes>
      </Suspense>
    </div>
  );
};
export default AppApprover;
