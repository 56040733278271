import CIcon from "@coreui/icons-react";
import { cilSpeedometer } from "@coreui/icons-pro";
import { CNavItem } from "@coreui/react-pro";
import { ElementType } from "react";
import { ApproverRoutePath } from "./_approver.route-path";

export type Badge = {
  color: string;
  text: string;
};

export type NavItem = {
  component: string | ElementType;
  name: string | JSX.Element;
  icon?: string | JSX.Element;
  badge?: Badge;
  to: string;
  items?: NavItem[];
};

const approverNav = [
  {
    component: CNavItem,
    name: "Dashboard",
    icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
    to: ApproverRoutePath.Dashboard,
  },
];

export default approverNav;
