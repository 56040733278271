import { useDispatch } from "react-redux";
import { useTypedSelector } from "../../../../store";
import {
  storeApproverSelectSidebarShow,
  storeApproverSelectSidebarUnfoldable,
  storeApproverSetLayout,
} from "../datasources/_approver.layout.store.slice";

export const useApproverSidebarController = () => {
  const dispatch = useDispatch();
  const unfoldable = useTypedSelector(storeApproverSelectSidebarUnfoldable);
  const sidebarShow = useTypedSelector(storeApproverSelectSidebarShow);

  const setSidebarShow = (visible: boolean) => {
    dispatch(storeApproverSetLayout({ type: "set", sidebarShow: visible }));
  };
  const toggleSidebarFold = () => {
    dispatch(
      storeApproverSetLayout({ type: "set", sidebarUnfoldable: !unfoldable }),
    );
  };

  return {
    state: {
      unfoldable,
      sidebarShow,
    },
    action: {
      setSidebarShow,
      toggleSidebarFold,
    },
  };
};
