import { Navigate, Outlet } from "react-router-dom";
import { useApproverLayoutController } from "./controllers/useApproverLayoutController";
import { LoadingOverlay } from "../../../components";
import ApproverSidebar from "./components/ApproverSidebar";
import ApproverHeader from "./components/ApproverHeader";
import ApproverFooter from "./components/ApproverFooter";
import { MainAppsRepo } from "../../../Apps";
import { FC } from "react";

interface ApproverAppLayoutProps {
  changeApp?: (app: MainAppsRepo) => void;
}

const ApproverAppLayout: FC<ApproverAppLayoutProps> = ({ changeApp }) => {
  // Controllers
  const { state: approverLayoutState } = useApproverLayoutController();

  // States
  const loading = approverLayoutState.loading;
  const isAuthenticated = approverLayoutState.isAuthenticated;

  // Views
  if (!loading && !isAuthenticated) {
    return <Navigate to="/approver/login" replace />;
  }

  if (loading) {
    return (
      <div className="text-center">
        <LoadingOverlay isOpen={true} />
      </div>
    );
  }

  // Layout
  return (
    <div>
      <ApproverSidebar changeApp={changeApp} />
      <div className="wrapper d-flex flex-column min-vh-100 bg-light dark:bg-transparent">
        <ApproverHeader />
        <div className="body flex-grow-1 px-3">
          <Outlet />
        </div>
        <ApproverFooter />
      </div>
    </div>
  );
};

export default ApproverAppLayout;
