import { useOtherRestoreAuthRepo } from "../../authentication/repositories/useOtherRestoreAuthRepo";

export const useOtherLayoutController = () => {
  const {
    state: { loading, isAuthenticated },
  } = useOtherRestoreAuthRepo();

  return {
    state: {
      loading,
      isAuthenticated,
    },
  };
};
