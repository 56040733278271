import { PayloadAction, createSlice } from "@reduxjs/toolkit";
// import { ReduxStore } from "../../../../store";
import { UserModel } from "../../../types/models";
import { LogonResponse } from "../../../types/responses";
import { LoginCredentialParam } from "../../../types/params";
import { PtwStoreSlices } from "../../../configs/_.store.slice";
import { UserRoleModel } from "../../../types/models/userRole.model";
import { LoginType } from "./constants/login-type";

export interface PtwAuthStoreSlice {
  id: string | null;
  user: UserModel | null;
  accessToken: string | null;
  refreshToken: string | null;
  credential: LoginCredentialParam;
  role: UserRoleModel[];
  loginType: LoginType;
}

const initialState: PtwAuthStoreSlice = {
  id: null,
  user: null,
  accessToken: null,
  refreshToken: null,
  credential: {
    email: null,
    password: null,
  },
  role: [],
  loginType: LoginType.None,
};

const ptwAuthSlice = createSlice({
  name: "ptwAuth",
  initialState,
  reducers: {
    storePtwSetUserCredential: (
      state,
      action: PayloadAction<LoginCredentialParam>,
    ) => {
      state.credential = action.payload;
    },
    storePtwClearUserCredential: (state, _action) => {
      state.credential = {
        email: null,
        password: null,
      };
    },
    storePtwSetCredential: (state, action: PayloadAction<LogonResponse>) => {
      const { id, user, accessToken, refreshToken, role, loginType } =
        action.payload;
      state.id = id;
      state.user = user;
      state.accessToken = accessToken;
      state.refreshToken = refreshToken;
      state.role = role ?? [];
      state.credential = {
        email: null,
        password: null,
      };
      state.loginType = loginType ?? LoginType.None;
    },
    storePtwClearCredential: (state, _action: PayloadAction<void>) => {
      state.user = null;
      state.accessToken = null;
      state.refreshToken = null;
      state.role = [];
      state.credential = {
        email: null,
        password: null,
      };
      state.loginType = LoginType.None;
    },
  },
});

// Actions
export const {
  storePtwSetCredential,
  storePtwClearCredential,
  storePtwSetUserCredential,
  storePtwClearUserCredential,
} = ptwAuthSlice.actions;

// Selector
export const storePtwSelectCurrentUser = (state: PtwStoreSlices) =>
  state.ptwAuth.user;
export const storePtwSelectCredential = (state: PtwStoreSlices) =>
  state.ptwAuth.credential;
export const storePtwSelectAccessToken = (state: PtwStoreSlices) =>
  state.ptwAuth.accessToken;
export const storePtwSelectRefreshToken = (state: PtwStoreSlices) =>
  state.ptwAuth.refreshToken;
export const storePtwSelectCurrentRole = (state: PtwStoreSlices) =>
  state.ptwAuth.role;
export const storePtwSelectLoginType = (state: PtwStoreSlices) =>
  state.ptwAuth.loginType;
export const storePtwSelectCurrentId = (state: PtwStoreSlices) =>
  state.ptwAuth.id;

// Reducers
export default ptwAuthSlice.reducer;
