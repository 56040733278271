import React, { ReactNode } from "react";
import { ApproverRoutePath } from "./_approver.route-path";
const ApproverDashboard = React.lazy(
  () => import("../features/dashboard/ApproverDashboard"),
);
const ApproverNotFoundError = React.lazy(
  () => import("../features/errors/ApproverNotFoundError"),
);
const ApproverInternalServerError = React.lazy(
  () => import("../features/errors/ApproverInternalServerError"),
);

export type route = {
  component?: ReactNode;
  name?: string;
  path?: string;
  routes?: route[];
  paramIndex?: number;
  disabled?: undefined | boolean;
};

const approverRoutes = [
  { path: ApproverRoutePath.Home, name: "Home" },
  {
    path: ApproverRoutePath.Dashboard,
    name: "Dashboard",
    component: ApproverDashboard,
  },
  {
    path: ApproverRoutePath.NotFoundError,
    name: "Page Not Found",
    component: ApproverNotFoundError,
  },
  {
    path: ApproverRoutePath.InternalServerError,
    name: "Internal Server Error",
    component: ApproverInternalServerError,
  },
];

export default approverRoutes;
