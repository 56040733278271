import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { storePtwSetCredential } from "../datasources/_ptw.auth.store.slice";
import { useTypedSelector } from "../../../../store";
import { LogonResponse } from "../../../types/responses";

export const usePtwRestoreAuthRepo = () => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState<boolean>(true);
  const accessToken = useTypedSelector(({ ptwAuth }) => ptwAuth.accessToken);

  useEffect(() => {
    const logonString =
      localStorage.getItem("PtwLogon") ?? sessionStorage.getItem("PtwLogon");

    if (!logonString) {
      setLoading(false);
      return;
    }
    try {
      const logon = JSON.parse(logonString) as LogonResponse;
      dispatch(storePtwSetCredential(logon));
      setLoading(false);
    } catch (e) {
      return setLoading(false);
    }
  }, [dispatch]);
  return [
    {
      loading,
      isAuthenticated: accessToken ? true : false,
    },
  ] as const;
};
