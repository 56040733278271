import React, { forwardRef, HTMLAttributes } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { SLink } from "./SLink";
import { NavLink } from "react-router-dom";
import { CLink } from "@coreui/react-pro";

export interface SBreadcrumbItemProps extends HTMLAttributes<HTMLLIElement> {
  /**
   * Toggle the active state for the component.
   */
  active?: boolean;
  /**
   * A string of all className you want applied to the base component.
   */
  className?: string;
  /**
   * The `href` attribute for the inner `<CLink>` component.
   */
  href?: string;

  /**
   * Configure the inner `<CLink>` component to use NavLink and treat 'href' as 'to'.
   */
  navlink?: boolean;
}

export const SBreadcrumbItem = forwardRef<HTMLLIElement, SBreadcrumbItemProps>(
  ({ children, active, className, href, navlink, ...rest }, ref) => {
    const child = !navlink ? (
      href ? (
        <CLink href={href}>{children}</CLink>
      ) : (
        children
      )
    ) : (
      <SLink to={href} component={NavLink}>
        {children}
      </SLink>
    );
    return (
      <li
        className={classNames(
          "breadcrumb-item",
          {
            active: active,
          },
          className,
        )}
        {...(active && { "aria-current": "page" })}
        {...rest}
        ref={ref}
      >
        {child}
      </li>
    );
  },
);

SBreadcrumbItem.propTypes = {
  active: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  href: PropTypes.string,
};

SBreadcrumbItem.displayName = "SreadcrumbItem";
