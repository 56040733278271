import { Navigate, Outlet } from "react-router-dom";
import { useOtherLayoutController } from "./controllers/useOtherLayoutController";
import { LoadingOverlay } from "../../../components";
import OtherSidebar from "./components/OtherSidebar";
import OtherHeader from "./components/OtherHeader";
import OtherFooter from "./components/OtherFooter";

const OtherAppLayout = () => {
  // Controllers
  const { state: starterLayoutState } = useOtherLayoutController();

  // States
  const loading = starterLayoutState.loading;
  const isAuthenticated = starterLayoutState.isAuthenticated;

  // Views
  if (!loading && !isAuthenticated) {
    return <Navigate to="/starter/login" replace />;
  }

  if (loading) {
    return (
      <div className="text-center">
        <LoadingOverlay isOpen={true} />
      </div>
    );
  }

  // Layout
  return (
    <div>
      <OtherSidebar />
      <div className="wrapper d-flex flex-column min-vh-100 bg-light dark:bg-transparent">
        <OtherHeader />
        <div className="body flex-grow-1 px-3">
          <Outlet />
        </div>
        <OtherFooter />
      </div>
    </div>
  );
};

export default OtherAppLayout;
