import { useDispatch } from "react-redux";
import {
  storePtwSelectSidebarShow,
  storePtwSelectSidebarUnfoldable,
  storePtwSetLayout,
} from "../datasources/_.layout.store.slice";
import { usePtwTypedSelector } from "../../../configs/_.store.slice";
import { UserRoleModel } from "../../../types/models/userRole.model";
import { storePtwSelectCurrentRole } from "../../authentication/datasources/_ptw.auth.store.slice";

export const usePtwSidebarController = () => {
  const dispatch = useDispatch();
  const unfoldable = usePtwTypedSelector<boolean>(
    storePtwSelectSidebarUnfoldable,
  );
  const sidebarShow = usePtwTypedSelector<boolean>(storePtwSelectSidebarShow);
  const userRoles = usePtwTypedSelector<UserRoleModel[]>(
    storePtwSelectCurrentRole,
  );

  const setSidebarShow = (visible: boolean) => {
    dispatch(storePtwSetLayout({ type: "set", sidebarShow: visible }));
  };
  const toggleSidebarFold = () => {
    dispatch(
      storePtwSetLayout({ type: "set", sidebarUnfoldable: !unfoldable }),
    );
  };

  return {
    state: {
      unfoldable,
      sidebarShow,
      userRoles,
    },
    action: {
      setSidebarShow,
      toggleSidebarFold,
    },
  };
};
