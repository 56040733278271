//./node_modules/.bin/prettier --write "src/**/*.{js,jsx,ts,tsx,json,css,scss,md}"
import { FC } from "react";
import { InfinitySpin } from "react-loader-spinner";
import "./loading-overlay.scss";
export interface LoadingOverlayProps {
  isOpen: boolean;
  src?: string | undefined;
}
const LoadingOverlay: FC<LoadingOverlayProps> = ({
  isOpen = false,
  src = undefined,
}) => {
  return (
    <div className={`loading-overlay ${isOpen ? "open" : ""}`}>
      <div className="loader">
        {src && (
          <img
            src={src ?? undefined}
            width={"320px"}
            alt="Guard Expert Pro Logo"
          />
        )}
        <InfinitySpin width="200" color="#ea5a5a" />
      </div>
    </div>
  );
};

export default LoadingOverlay;
