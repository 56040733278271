import CIcon from "@coreui/icons-react";
import { cilSpeedometer } from "@coreui/icons-pro";
import { CNavItem } from "@coreui/react-pro";
import { ElementType } from "react";
import { OtherRoutePath } from "./_other.route-path";

export type OtherBadge = {
  color: string;
  text: string;
};

export type OtherNavItem = {
  component: string | ElementType;
  name: string | JSX.Element;
  icon?: string | JSX.Element;
  badge?: OtherBadge;
  to: string;
  items?: OtherNavItem[];
};

const otherNav = [
  {
    component: CNavItem,
    name: "Dashboard",
    icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />,
    to: OtherRoutePath.Dashboard,
  },
];

export default otherNav;
