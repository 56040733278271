import { createSlice } from "@reduxjs/toolkit";
import { PtwStoreSlices } from "../../../configs/_.store.slice";

export interface PtwLayoutStoreSlice {
  sidebarShow: boolean;
  sidebarUnfoldable: boolean;
  asideShow: boolean;
  theme: string;
}

const initialState: PtwLayoutStoreSlice = {
  sidebarShow: true,
  sidebarUnfoldable: false,
  asideShow: false,
  theme: "light",
};

const ptwLayoutSlice = createSlice({
  name: "ptwLayout",
  initialState,
  reducers: {
    storePtwSetLayout: (state, { payload }) => {
      return { ...state, ...payload };
    },
  },
});

// Actions
export const { storePtwSetLayout: storePtwSetLayout } = ptwLayoutSlice.actions;

// Selectors
export const storePtwSelectLayout = (state: PtwStoreSlices) => state.ptwLayout;

export const storePtwSelectSidebarShow = (state: PtwStoreSlices) =>
  state.ptwLayout.sidebarShow;

export const storePtwSelectSidebarUnfoldable = (state: PtwStoreSlices) =>
  state.ptwLayout.sidebarUnfoldable;

export const storePtwSelectAsideShow = (state: PtwStoreSlices) =>
  state.ptwLayout.asideShow;

export const storePtwSelectTheme = (state: PtwStoreSlices) =>
  state.ptwLayout.theme;

// Reducer
export default ptwLayoutSlice.reducer;
