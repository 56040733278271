import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ApproverRoutePath } from "./app.approver/configs/_approver.route-path";
import { OtherRoutePath } from "./app.other/configs/_other.route-path";
import { useState } from "react";

export enum MainAppsRepo {
  Approver,
  Ptw,
}

import AppPtw from "./app.ptw/AppPtw";
import AppApprover from "./app.approver/AppApprover";
import AppOther from "./app.other/AppOther";

const Apps = () => {
  const [app, setApp] = useState<MainAppsRepo>(MainAppsRepo.Ptw);

  let MainApp: JSX.Element = (
    <Route path="/*" element={<AppPtw changeApp={setApp} />} />
  );
  switch (app) {
    case MainAppsRepo.Approver:
      MainApp = (
        <Route path="/*" element={<AppApprover changeApp={setApp} />} />
      );
  }

  return (
    <BrowserRouter>
      <Routes>
        {/* Sub Applications e.g. named Other */}
        <Route
          path={`/${ApproverRoutePath.Prefix}/*`}
          element={<AppApprover />}
        />
        <Route path={`/${OtherRoutePath.Prefix}/*`} element={<AppOther />} />

        {/* Main Application */}
        {MainApp}
      </Routes>
    </BrowserRouter>
  );
};

export default Apps;
