import React from "react";
import {
  CSidebar,
  CSidebarBrand,
  CSidebarNav,
  CSidebarToggler,
} from "@coreui/react-pro";

import { OtherSidebarNav } from "./widgets/OtherSidebarNav";

import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";

import { logoNegative as starterLogo } from "../../../../assets/brand/coreui-logo-sidebar";
import { sygnet as starterCygnet } from "../../../../assets/brand/coreui-sygnet-sidebar";

// sidebar nav config
import navigation from "../../../configs/_other.sidenav";
import { useOtherSidebarController } from "../controllers/useOtherSidebar";
import CIcon from "@coreui/icons-react";

const OtherSidebar = () => {
  const {
    state: { sidebarShow, unfoldable },
    action: { setSidebarShow, toggleSidebarFold },
  } = useOtherSidebarController();

  return (
    <CSidebar
      position="fixed"
      unfoldable={unfoldable}
      visible={sidebarShow}
      onVisibleChange={(visible) => {
        setSidebarShow(visible);
      }}
    >
      <CSidebarBrand className="d-none d-md-flex">
        <CIcon className="sidebar-brand-full" icon={starterLogo} height={35} />
        <CIcon
          className="sidebar-brand-narrow"
          icon={starterCygnet}
          height={35}
        />
      </CSidebarBrand>
      <CSidebarNav>
        <SimpleBar>
          <OtherSidebarNav items={navigation} />
        </SimpleBar>
      </CSidebarNav>
      <CSidebarToggler
        className="d-none d-lg-flex"
        onClick={() => {
          toggleSidebarFold();
        }}
      />
    </CSidebar>
  );
};

export default React.memo(OtherSidebar);
