import { useDispatch } from "react-redux";
import { useTypedSelector } from "../../../../store";
import {
  storeApproverSelectTheme,
  storeApproverSetLayout,
} from "../datasources/_approver.layout.store.slice";

export const useApproverThemeSwitcher = () => {
  const dispatch = useDispatch();
  const theme = useTypedSelector(storeApproverSelectTheme);

  theme === "dark"
    ? document.body.classList.add("dark-theme")
    : document.body.classList.remove("dark-theme");

  const setLightTheme = () => {
    dispatch(storeApproverSetLayout({ theme: "light" }));
  };

  const setDarkTheme = () => {
    dispatch(storeApproverSetLayout({ theme: "dark" }));
  };

  return {
    state: { theme },
    action: { setLightTheme, setDarkTheme },
  };
};
