import React, { Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { OtherRoutePath } from "./configs/_other.route-path";
import otherRoutes from "./configs/_other.routes";
import OtherAppLayout from "./features/layout/OtherAppLayout";
const OtherDashboard = React.lazy(
  () => import("./features/dashboard/OtherDashboard"),
);

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

const AppOther = () => {
  return (
    <Suspense fallback={loading}>
      <Routes>
        <Route path={OtherRoutePath.Home} element={<OtherAppLayout />}>
          {otherRoutes.map((route, idx) => {
            return (
              route.component && (
                <Route
                  key={idx}
                  path={route.path}
                  element={<route.component />}
                />
              )
            );
          })}
          <Route path={OtherRoutePath.Dashboard} element={<OtherDashboard />} />
          <Route
            path="*"
            element={<Navigate to={OtherRoutePath.Dashboard} replace />}
          />
        </Route>
      </Routes>
    </Suspense>
  );
};
export default AppOther;
