import React from "react";
import { CFooter } from "@coreui/react-pro";
import moment from "moment";

const ApproverFooter = () => {
  return (
    <CFooter>
      <div>
        <a href="" target="_blank" rel="noopener noreferrer">
          Significs Technologies Sdn. Bhd.
        </a>
        <span className="ms-1">&copy; {moment().format("YYYY")} .innates</span>
      </div>
      <div className="ms-auto">
        <p className="me-1">
          Powered by <span className="text-primary">IoT Wave</span>
        </p>
      </div>
    </CFooter>
  );
};

export default React.memo(ApproverFooter);
